const validateMessages = {
  required: '${label} é obrigatório!',
  types: {
    email: '${label} tem de ser um email válido!',
    number: '${label} term de ser um número válido',
  },
  number: {
    range: '${label} deve estar entre ${min} e ${max}',
  },
  string: {
    len: '${label} tem de ter ${len} números',
  },
}

export default validateMessages
